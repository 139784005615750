  import React, { useEffect, useRef } from 'react';
  import {  Box, Typography,  Container, } from '@mui/material';
  import { useLanguage } from '../LanguageContext';
  import translations from '../translations';
  import { useNavigate } from 'react-router-dom';

const News = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const formRef = useRef(null);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
    
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "25343058",
            formId: "703d887d-cb93-40ad-bebf-1ea81fdc59df",
            target: "#hubspotForm"
          });
        }
      });
    }, []);
  
    return (
  <><Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          pt: { xs: '120px', sm: '150px', md: '280px', lg: '400px' },
          pb: { xs: '30px', sm: '50px', md: '80px', lg: '80px' },
        }}
      >
        <Container>
          <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
                color: '#ffffff',
                textAlign: 'center',
              }}
            >
              {translations[language]['News']}
            </Typography>
          </Box>
  
          <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                color: '#ffffff',
                textAlign: 'center',
              }}
            >
              {translations[language]["Al momento non ci sono news. Per rimanere aggiornato sulle iniziative Carta Reale, iscriviti alla nostra newsletter."]}<br />
             
            </Typography>

            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                color: '#ffffff',
                textAlign: 'center',
                mt: 4,
              }}
            >
              
              {translations[language]["Tutti gli eventi e le novità dell’ esclusivo mondo Carta Reale direttamente nella tua mail"]}
            </Typography>

</Box>
<Container>
              {/* Existing content */}
              <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
              color: '#ffffff',
              textAlign: 'center',
              mb: 4,
            }}
          >
                {translations[language]['Iscriviti alla Newsletter']}
              </Typography>
              {/* More existing content */}
              <div ref={formRef} id="hubspotForm"></div>
            </Container>


  
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            
          </Box>
        </Container>
      </Box>
      
      </>
  );
  };
  

export default News;