import React from 'react';
import { Box, Typography, } from '@mui/material';
  import { useLanguage } from '../LanguageContext';
  import translations from '../translations';
  import { useNavigate } from 'react-router-dom';


  const Terms = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
  
    return (
   <div>
  
  <>
  <Box sx={{ pt: { xs: '100px', sm: '120px', md: '150px', lg: '150px' }, px: 2, paddingLeft: { xs: '40px', md: '100px' }, paddingRight: { xs: '40px', md: '100px' } }}>
  <Box sx={{ py: 4, px: { xs: 2, md: 6 } }}>
  <Typography variant="h1" sx={{ fontWeight: 'bold',textAlign: 'left', fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' }, mr:2, mt: 5, mb: 5 }}>
  {translations[language]['Terms and Conditions of Use of the Service']}
   </Typography>
   <Typography variant="h2" sx={{ fontWeight: 'bold',textAlign: 'left', fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' }, mr:2, mt: 5, mb: 5 }}>
  {translations[language]['About us']}
   </Typography>
   <Typography variant="h1" sx={{ textAlign: 'left', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' }, mr:2, mt: 5, mb: 5 }}>
  {translations[language]['First Digital Trade Europe UAB, a company with 306129492 registration number and registered address at Kaykyos 18-10 01100 Vilnius Lithuania, you can contact us by email at contact@firstdigitaltrade.com']}
   </Typography>

  </Box>
  
</Box>
 
     </> 
    </div>
    );
  };





export default Terms;
